import Service from "./Service";
const baseurl = '/api/partograma';

export default {
    store(obj) {
        return Service.post(`${baseurl}/store`, obj);
    },
    show(id) {
        return Service.get(`${baseurl}/show/${id}`);
    },
    showById(id) {
        return Service.get(`${baseurl}/show-by-idUsuario/${id}`);
    },
    showByLast(id) {
        return Service.get(`${baseurl}/show-by-last/${id}`);
    },
    update(obj) {
        return Service.put(`${baseurl}/update`, obj);
    },
    close(id){
        return Service.get(`${baseurl}/close/${id}`);
    }
}
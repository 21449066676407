<template>
    <main>
        <div class="modal fade" id="modalPartograma" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Registro</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-2">
                            <div class="col-lg-4 form-group">
                                <label class="small mb-1 font-weight-bolder">Hora <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.hora" class="form-control form-control-sm" type="time">
                            </div>
                            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                                <label class="small mb-1 font-weight-bolder">Posición materna <small
                                        class="text-danger">*</small></label>
                                    <select class="form-control" v-model="partograma.posicion">
                                        <option value="LD">LATERAL DERECHO</option>
                                        <option value="LI">LATERAL IZQUIERDO</option>
                                        <option value="D">DORSAL</option>
                                        <option value="SS">SEMISENTADA</option>
                                        <option value="S">SENTADA</option>
                                        <option value="PC">PARADA O CAMINANDO</option>
                                    </select>
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Tensión arterial sistólica <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.ta_sistolica" class="form-control form-control-sm" type="number"
                                    />
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Tensión arterial diastólica <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.ta_diastolica" class="form-control form-control-sm" type="number"
                                    />
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Pulso <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.pulso" class="form-control form-control-sm" type="number"
                                    />
                            </div>
                            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                                <label class="small mb-1 font-weight-bolder">Frecuencia cardiaca fetal <small
                                        class="text-danger">*</small></label>
                                    <select class="form-control" v-model="partograma.frecuencia_fetal">
                                        <option value="DIPS TIPO 1">DIPS TIPO 1 (DESACELERACION PRECOZ)</option>
                                        <option value="DIPS TIPO 2">DIPS TIPO 2 (DESACELERACION TARDIA)</option>
                                        <option value="DIPS VARIABLES">DIPS VARIABLES (DESACELERACION VARIABLE)</option>
                                        <option value="MECONIO">MECONIO</option>
                                    </select>
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Duración contracciones <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.duracion" class="form-control form-control-sm" type="number"
                                    />
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Frec. Contracciones <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.frecuencia_contracciones" class="form-control form-control-sm" type="number"
                                    />
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Dolor (Localización) <small
                                        class="text-danger">*</small></label>
                                        <select class="form-control" v-model="partograma.localizacion">
                                        <option value="FUERTE">FUERTE +++</option>
                                        <option value="NORMAL">NORMAL ++</option>
                                        <option value="DEBIL">DEBIL +</option>
                                        <option value="MECONIO">MECONIO</option>
                                    </select>
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Dolor (Intensidad) <small
                                        class="text-danger">*</small></label>
                                        <select class="form-control" v-model="partograma.intensidad">
                                        <option value="SP">SUPRAPUBLICO (SP)</option>
                                        <option value="S">SACRO (S)</option>
                                    </select>
                            </div>
                            <div class="col-lg-4">
                                <label class="small mb-1 font-weight-bolder">Dilatación (Cm) <small
                                        class="text-danger">*</small></label>
                                <input v-model="partograma.dilatacion" class="form-control form-control-sm" type="number"
                                    />
                            </div>
                        </div>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light btn-sm" data-dismiss="modal"><i
                            class="far fa-times-circle fa-fw"></i> Cerrar</button>
                </div>
            </div>
        </div>
        </div>
    </main>
</template>
<script>
import Toast from '../../../../components/common/utilities/toast';
import partogramaService from '../../../../services/partogramaService';

export default {
    props: ['usuario'],
    data() {
        return {
            partograma:{
                hora:'',
                posicion:'',
                ta_sistolica:'',
                ta_diastolica:'',
                pulso:'',
                frecuencia_fetal:'',
                duracion:'',
                frecuencia_contracciones:'',
                localizacion:'',
                intensidad:'',
                dilatacion:''
            }
        }
    },
    methods: {
        async guardar() {
            try {
                this.LoaderSpinnerShow();
                await partogramaService.store(this.partograma);
                this.limpiarCampos();
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                //this.$emit('cargar-aplicaciones');
                //this.$emit('newAplicacion');
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
    },
}
</script>
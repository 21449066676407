<template>
    <main>
        <modalInformacionUsuario :usuario="usuario"></modalInformacionUsuario>
        <page-header-compact>
            Partograma
            <template v-slot:button>
                <router-link :to="{ name: 'historias.perfil.usuario', params: { idUsuario: usuario.id } }"
                    class="btn btn-success btn-sm shadow-sm mx-2">
                    Regresar al perfil del paciente <i class="fas fa-history fa-fw"></i>
                </router-link>
            </template>
        </page-header-compact>
        <div class="container-xl px-4">
            <div class="card mt-2">
                <cardInformacionUsuario :usuario="usuario"></cardInformacionUsuario>
            </div>
            <div class="row gutters-sm">
                <div class="col-md-4 mb-3">
                    <div class="card mt-2 text-center">
                        <div class="card-body">
                            <table class="table table-bordered table-sm" style="font-size: 12px;">
                                <tr class="font-weight-bolder">
                                    <td></td>
                                    <td>POSICIÓN</td>
                                    <td>PARIDAD</td>
                                    <td>MEMBRANAS</td>
                                </tr>
                                <tr v-for="(item, index) in dataPartograma" :key="index">
                                    <td><input class="form-check-input" type="radio" id="radio" :value="item"
                                            v-model="selectPosition"></td>
                                    <td>{{ item.posicion }}</td>
                                    <td>{{ item.paridad }}</td>
                                    <td>{{ item.membranas }}</td>
                                </tr>
                            </table>
                            <button class="btn btn-info btn-sm mt-2" data-toggle="modal" data-target="#modalPartograma">Registrar datos</button>
                            <modal></modal>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card mt-2">
                        <div class="card-header">Partograma</div>
                        <div class="card-body">
                            <chart :dataCurve="selectPosition"></chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import modalInformacionUsuario from "../../base/informacionUsuario/modalInformacionUsuario";
import cardInformacionUsuario from "../../base/informacionUsuario/cardInformacionUsuario";
import usuarioService from '../../../../services/usuarioService';
import Toast from '../../../../components/common/utilities/toast';
import partogramaJson from '../../../../assets/datasheets/charts/partograma.json';
import chart from "./chart.vue"
import modal from "./modal.vue";
import partogramaService from "../../../../services/partogramaService";
export default {
    components: { PageHeaderCompact, modalInformacionUsuario, cardInformacionUsuario, chart, modal },
    data() {
        return {
            usuario: {},
            partograma: {},
            dataPartograma: partogramaJson,
            selectPosition: {}
        }
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarPartograma(id) {
            const response = await partogramaService.show(id);
            this.partograma = response.data;
        },
    },
    async created() {
        try {
            this.LoaderSpinnerShow();
            await this.cargarPartograma(this.$route.params.id);
            await this.cargarUsuario(this.partograma.id_usuario);
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }

    }
}
</script>
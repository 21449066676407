<template>
    <main>
        <canvas id="partograma" class="canvas-h canvas-w"></canvas>
    </main>
</template>

<script>
import Chart from 'chart.js/auto';
import chartData from '../../../../assets/datasheets/charts/curveAlert.json';

export default {
    props: ['x', 'y', 'dataCurve'],
    data(){
        return {
            chartInstance:null
        }
    },
    methods: {
        open() {
            const labels = chartData.datapoints;
            const datasets = [];

            chartData.series.forEach(s => {
                datasets.push({
                    type: 'line',
                    label: s.title,
                    data: s.data,
                    borderColor: s.color,
                    fill: false,
                    tension: 0.5,
                    borderWidth: 1.5,
                });
            });

            datasets.push({
                type: 'scatter',
                backgroundColor: 'rgba(75, 00, 150,1)',
                borderColor: 'rgba(75, 00, 150,1)',
                label: 'Curva de alerta',
                data: [{
                    "x": Math.floor(this.dataCurve),
                    "y": this.y
                }],
                elements: {
                    point: {
                        radius: 4
                    }
                }
            });

            const data = {
                labels: labels,
                datasets: datasets
            };

            const config = {
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        title: {
                            display: true,
                            text: 'Partograma'
                        },
                    },
                    interaction: {
                        intersect: false,
                    },
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Horas'
                            },
                            ticks: {
                                min: 0, // Iniciar en 5 años (60 meses)
                                max: 14,
                                callback: function (value) {
                                    if (Number(this.getLabelForValue(value)) % 60 === 0) {
                                        return `${Number(this.getLabelForValue(value)) / 60}`;
                                    }
                                },
                                stepSize: 1, // Ajustar el paso según sea necesario
                                // Terminar en 19 años (228 meses)
                            },
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: "Dilatación (Cm)",
                            },
                            ticks: {
                                stepSize: 1, // Intervalos de 10 cm
                                min: 0, // Altura mínima
                                max: 10, // Altura máxima
                            },
                        },
                    },
                    elements: {
                        point: {
                            radius: 0
                        }
                    }
                },
            };

            const ctx = document.getElementById("partograma").getContext("2d");
            if (this.chartInstance) {
                this.chartInstance.destroy();
            }
            this.chartInstance = new Chart(ctx, config);        
        },
        addSeries() {
            if (!this.dataCurve || !this.dataCurve.labels || !this.dataCurve.dilatation) {
                console.error("dataCurve is missing or invalid");
                return;
            }

            const labels = this.dataCurve.labels.map((label) => label / 60); // Convertir etiquetas a horas
            const dilatation = this.dataCurve.dilatation;

            // Agregar nueva serie
            

            //this.chartInstance.data.datasets.push(newDataset); // Agregar al dataset
             // Actualizar la gráfica

            this.chartInstance.data.datasets.push({
                type: 'scatter',
                backgroundColor: 'rgba(75, 00, 150,1)',
                borderColor: 'rgba(75, 00, 150,1)',
                label: 'Curva de alerta',
                data: dilatation.map((y, i) => ({ x: labels[i], y })),
                elements: {
                    point: {
                        radius: 4
                    }
                }
            });
            this.chartInstance.update();
        },
        getRandomColor() {
            const letters = "0123456789ABCDEF";
            let color = "#";
                   for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
    },
   
    watch: {
        dataCurve: function(){
            
            this.addSeries(); // Agregar una nueva serie cuando cambie dataCurve   
             
        },
    },
created() {
    this.$nextTick(() => this.open());
}
  }

</script>

<style scoped>
.canvas-h {
    height: 100%;
}

.canvas-w {
    width: 100%;
}
</style>